import { ChangeDetectorRef, Component } from "@angular/core";
import * as moment from "moment";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { AbstractRankDetailsGameComponent } from "../../../../abstract/custom/abstract-rank-game-details.component";
import { BusinessRanksService } from "../../../../services/custom/business-ranks.service";

@Component({
  selector: "app-ranking-details-game",
  templateUrl: "./ranking-details-game.component.html",
})
export class RankingDetailsGame extends AbstractRankDetailsGameComponent {
  @OwInject(BusinessRanksService) businessRanksService: BusinessRanksService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  rankStarted = null;
  rankFinished = null;
  owDate: OwDate = null;
  displayFilter = false;
  isActiveFilter = false;

  getGameRank() {
    this.apiCoreGameRanksService
      .getGameRank({
        rank_edition_id: this.data.rankId,
      })
      .subscribe(resp => {
        this.rank = resp;
        this.setRankConfig();
        this.getResult();
      });
  }

  checkDisplayFilterParameter() {
    const parameters = this.rank?.parameters?.enable_branch_filter_for_role;
    if (parameters?.length > 0) {
      this.displayFilter = parameters.includes(this.playerService.player.title_id.id);
    } else {
      this.displayFilter = false;
    }
  }

  toggleFilter() {
    this.isActiveFilter = !this.isActiveFilter;
    switch (this.rank.rank_type) {
      case 1:
        this.getGameRanksResults(this.isActiveFilter);
        break;

      case 2:
        this.getGameRankBranchesResult(this.isActiveFilter);
        break;
    }
  }

  getGameRanksResults(showMine = false) {
    const requestData = {
      rank_edition_id: this.rank.rank_edition_id,
      limit: this.getLimit(),
      show_mine: showMine ? "1" : undefined,
    };
    if (requestData)
      this.apiCoreGameRanksService.getGameRanksResults(requestData).subscribe(resp => {
        const response: any = resp;
        if (!Array.isArray(response?.results)) {
          response.results = Object.values(response.results);
        }
        this.rankResults = response;
        this.afterGetGameRankRequest();
        this.checkDisplayFilterParameter();
      });
  }

  getGameRankBranchesResult(showMine = false) {
    const requestData = {
      rank_edition_id: this.rank.rank_edition_id,
      limit: this.getLimit(),
      show_mine: showMine ? "1" : undefined,
    };
    this.apiCoreGameRanksService.gameRankBranchesResult(requestData).subscribe(resp => {
      const response: any = resp;
      if (!Array.isArray(response?.results)) {
        response.results = Object.values(response.results);
      }
      this.rankResults = response;
      this.afterGetGameRankRequest();
      this.checkDisplayFilterParameter();
    });
  }
}
